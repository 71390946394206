<template>
<div class="container-fluid">
<div class="row" >
  <div class="text-center col-sm-12 col-md-6 col-lg-6 ">
    <b-table striped hover :items="rowItems" :fields="tableCon.fields" responsive="sm">
      <template v-slot:cell(serial)="row">
        {{row.value}}
      </template>
      <template v-slot:cell(room_name4human)="row">
        {{row.value}}
      </template>
      <template #cell(status)="row">
        {{row.value}}
      </template>
      <template #cell(action)="row">
        <b-button size="sm" @click="copy2Form(row.item)">{{row.item.status === '未填寫'? '填寫表單': '檢視表單'}}</b-button>
      </template>
    </b-table>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-6" v-if="formData.uuid !== null">
    <div v-if="formData.isView === false">
      <h3>請填寫表單</h3>
      <b-form >
        <b-card bg-variant="light">
          <b-form-group
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-0"
          >
            <h1>本表單僅供記錄</h1>
            <h1 style="color:red">報修請自行至修繕網址</h1>
            <b-form-group
                id="roomName"
                label="房間名稱"
                label-for="roomName"
                description="目前所住的房間"
            >
              <b-form-input
                  id="roomName"
                  v-model="formData.nowRowItem.room_name4human"
                  disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="serialName"
                label="學年度"
                label-for="serialName"
                description="目前填寫表單的年度"
            >
              <b-form-input
                  id="roomName"
                  :value="formData.nowRowItem.serial"
                  disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="linkToFix"
            >
              <h3>
                <b-link href="https://wdsa.nttu.edu.tw/p/405-1009-74635,c8462.php?Lang=zh-tw"
                        target="_blank"
                >設備修繕網址
                </b-link>
              </h3>
              <h3>
                <b-link href="https://docs.google.com/forms/d/e/1FAIpQLSd1GCHNEXT7OX6KxjgSSsxPTWzDMTjUtqI_jGQjdTzeBGMQRg/viewform"
                        target="_blank"
                >網路修繕網址
                </b-link>
              </h3>
            </b-form-group>


            <!-- 使用者所填寫的 -->


            <b-form-group label="樓層群組:(如未加入請詢問樓長)"
                          v-slot="{ ariaDescribedby }"
                          v-if="formData.nowRowItem.status === '未填寫'"
            >
              <b-form-radio-group
                  v-model="line.selected"
                  :options="line.option"
                  :aria-describedby="ariaDescribedby"
                  name="radio-inline"
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group label="設備狀態:(沒有問題請勾選我的房間沒有問題)" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                  id="checkbox-group-0"
                  v-model="selected"
                  :options="formData.no4Option"
                  :aria-describedby="ariaDescribedby"
                  :disabled="formData.isView === true"
                  @change="isBroken"
                  name="myRoomNoProblem"
                  size="lg"
              >
              </b-form-checkbox-group>

              <!-- 多選房間設備的問題 -->

              <b-form-group label="個人區域:(設備有問題或有缺漏請勾選)" v-slot="{ ariaDescribedby }"
                            v-if="formData.nothingHappened === false">
                <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="selected"
                    :options="formData.brokenOption"
                    :aria-describedby="ariaDescribedby"
                    :disabled="formData.isView === true"
                    name="personalArea"
                    size="lg"
                ></b-form-checkbox-group>
              </b-form-group>

              <!-- 公共區域的表單群組選擇 -->

              <b-form-group label="公共區域:" v-slot="{ ariaDescribedby }"
                            v-if="formData.nothingHappened === false">
                <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="selected"
                    :options="formData.commonBrokenOption"
                    :aria-describedby="ariaDescribedby"
                    :disabled="formData.isView === true"
                    name="commonArea"
                    size="lg"
                ></b-form-checkbox-group>
              </b-form-group>

              <!-- 其他的多選單 -->

              <b-form-group label="其他:(若不在本表單上出現的額外問題請勾選並敘述)" v-slot="{ ariaDescribedby }"
                            v-if="formData.nothingHappened === false">
                <b-form-checkbox-group
                    id="checkbox-group-3"
                    v-model="selected"
                    :options="formData.otherBrokenValue"
                    :aria-describedby="ariaDescribedby"
                    :disabled="formData.isView === true"
                    @change="otherWrite"
                    name="otherToWrite"
                    size="lg"
                ></b-form-checkbox-group>
                <!-- 其他的文本輸入框 -->
                <b-form-textarea
                    id="textarea-state"
                    v-model="formData.otherBrokenText"
                    placeholder="請敘述狀況(請條列並填寫清楚)"
                    rows="3"
                    v-if="formData.otherBroken || formData.nowRowItem.status === '等待樓長審核'"
                    :disabled="formData.isView === true"
                ></b-form-textarea>

              </b-form-group>

              <!-- 送出按鈕 -->
              <b-button block
                        variant="primary"
                        :disabled="formData.isView"
                        @click="submit"
              >{{ formData.nowRowItem.status === '未填寫' || formData.nowRowItem.status === '等待樓長審核'? '提交' : '已繳交' }}</b-button>
            </b-form-group>
          </b-form-group>
        </b-card>
      </b-form>
    </div>
    <template v-else>
      <h3>檢視歷史記錄</h3>
      <div class="alert alert-primary" role="alert">
        樓長審核時間：{{formData.nowRowItem.floorMaster_is_verified? formData.nowRowItem.floorMaster_verify_time.toLocaleString(): '樓長尚未審核'}}<br>
        行政人員審核時間：{{formData.nowRowItem.admin_is_verified? formData.nowRowItem.admin_verify_time.toLocaleString(): '行政人員尚未審核'}}
      </div>
      <b-form v-if="formData.nowRowItem.device.length !== 0">
        <b-form-group label="設備狀態:" v-slot="{ ariaDescribedby }">

          <!-- 多選房間設備的問題 -->

          <b-form-group label="個人區域:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
                id="checkbox-group-5"
                v-model="formData.nowRowItem.device"
                :options="formData.brokenOption"
                :aria-describedby="ariaDescribedby"
                :disabled="formData.isView === true"
                name="personalArea"
                size="lg"
            ></b-form-checkbox-group>
          </b-form-group>

          <!-- 公共區域的表單群組選擇 -->

          <b-form-group label="公共區域:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
                id="checkbox-group-6"
                v-model="formData.nowRowItem.device"
                :options="formData.commonBrokenOption"
                :aria-describedby="ariaDescribedby"
                :disabled="formData.isView === true"
                name="commonArea"
                size="lg"
            ></b-form-checkbox-group>
          </b-form-group>

          <!-- 其他的多選單 -->

          <b-form-group label="其他:" v-slot="{ ariaDescribedby }">

            <!-- 其他的文本輸入框 -->

            <b-form-textarea
                id="textarea-state"
                v-model="formData.nowRowItem.other"
                placeholder="請敘述狀況"
                rows="3"
                :disabled="formData.isView === true"
                v-if="formData.nowRowItem.other.length !== 0"
            ></b-form-textarea>

          </b-form-group>


          <!-- 送出按鈕 -->
          <b-button block
                    variant="primary"
                    :disabled="formData.isView"
                    @click="submit"
          >已繳交</b-button>
        </b-form-group>
      </b-form>
      <h1 v-else>
        房間沒問題
      </h1>
    </template>
  </div>
</div>
</div>




</template>

<script>

import axios from 'axios'
export default {
  name: "checkForm",
  data: function (){
    return {
      rowItems:[
        // 資料存放的地方
      ],
      tableCon:{
        fields: [
          {
            key: 'serial',
            label: '住宿記錄',
            sortable: true,
          },
          {
            key: 'room_name4human',
            label: '床位',
          },
          {
            key: 'status',
            label: '狀態',
            sortable: false,
          },
          {
            key: 'action',
            label: '操作',
            sortable: false,
          }
        ],
      },
      formData:{
        uuid: null,

        isView: false,
        nowRowItem: null,

        // 2021.8.5更新
        //房間是否毀損
        nothingHappened: false,

        //表單選項
        no4Option: [
          { value: '', text: '我的房間沒問題'}
        ],

        brokenOption: [
          { value: 'bed_board', text: '床板' },
          { value: 'table', text: '書桌' },
          { value: 'wardrobe', text: '衣櫃'},
        ],
        // 公共區域表單選項
        commonBrokenOption: [
          {value: 'shower_head', text: '蓮蓬頭'},
          {value: 'washbasin', text: '洗手台'},
          {value: 'mirror', text: '鏡子'},
          {value: 'toilet', text: '馬桶'},
          {value: 'ac', text: '冷氣'},
          {value: 'ac_filter', text: '冷氣濾網'},
          {value: 'ac_controller', text: '冷氣遙控器'},
          {value: 'wall', text: '牆壁'},
          {value: 'glass', text: '陽台圍牆上玻璃片傾斜(限二宿一樓查看)'}
        ],
        otherBrokenValue : [
          {value: 'other', text: '其他'}
        ],
        // 其他區域填寫勾選
        otherBroken: false,
        // 其他區域選項文本填寫
        otherBrokenText: '',
        broken: '',

      },

      //使用者選擇損壞的東西
      selected:[],
      line: {
        option: [
          { text: '已加入群組', value: '1' }],
        selected: null
      }
    }
  },
  mounted: function() {
    this.update();
  },
  methods:{
    copy2Form: function (item){
      this.formData.nowRowItem = item;
      if(item.status === '未填寫' || item.status === '等待樓長審核'){
        // 新增資料

        this.formData.uuid = "待提交後產生" ;
        this.formData.isView = false ;
        this.selected = [] ;
        this.formData.isView = false ;
        if(item.device === null || item.device === []){
          this.selected = [];
        }else {
          this.selected = item.device ;
        }
        if(item.other !== null){
          this.formData.otherBroken = true
          this.formData.otherBrokenText = item.other
        }
      }else {

        // 檢視表單

        this.formData.isView = true;
        this.formData.uuid = item.form_id;

        if(item.device === null || item.device === ''){
          this.selected = [];
        }
        else {
          this.selected = item.device ;
        }
      }
    },
    submit: function() {

      if(this.line.selected === null && this.formData.nowRowItem.status === '未填寫')
      {
        window.alert('請加入樓層群組後並勾選')
      }
      else{
        let formData = new FormData();
        formData.append('devices', JSON.stringify(this.selected))
        formData.append('other', this.formData.otherBrokenText);
        formData.append( 'bed_history_id',this.formData.nowRowItem.bed_history_id);
        axios.post('/api/checkin_form/user',formData)
            .then(()=>{
               window.alert('提交成功');
               window.location.reload();
               axios.get('/api/checkin_form/user')
                   .then((response)=>{
                 this.rowItems = response.data;
               })
               })
        .catch((error)=>{
          //錯誤代碼
          if(error.response.status === 404 || error.response.data.hasOwnProperty('msg')){
            window.alert(error.response.data.msg);
            this.upload.sendBtnMsg = '重新提交';
          }else{
            window.alert('不明的錯誤');
            this.upload.sendBtnMsg = '重新提交';
          }
        })
      }},
    update: function(){
      axios.get('/api/checkin_form/user')
          .then((response)=>{
            let temp;
            let output = [];
            for(let item of response.data) {
              temp = Object.assign(item);
              if (temp["floorMaster_verify_time"] !== null)
                temp["floorMaster_verify_time"] = new Date(temp["floorMaster_verify_time"]).toLocaleString();
              if (temp["admin_verify_time"] !== null)
                temp["admin_verify_time"] = new Date(temp["admin_verify_time"]).toLocaleString();
              output.push(temp)
            }
            this.rowItems = output;
            //window.console.log(this.rowItems)
      }).catch((error)=>{
        window.console.log(error);
      })
    },

    // 判斷是否有壞的地方
    isBroken() {

      this.formData.nothingHappened = !this.formData.nothingHappened ;
      // 其他的判斷
      this.selected.length = 0;
      this.formData.otherBroken = false;
      this.formData.otherBrokenText = '';


    },
    // 判斷其他選項的填寫
    otherWrite() {
      this.formData.otherBroken = !this.formData.otherBroken;
      this.formData.otherBrokenText = '';
    },

  }

}

</script>

<style scoped>

</style>
